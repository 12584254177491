import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Calendars from "../components/Calendar"
import CourseCards from "../components/CourseCards"
import PascendiBannerLayout2 from "../components/PascendiBannerLayout2"
import Countdown from "../components/Countdown"

const IndexPage = () => {
  const targetDate = "2023-08-21T00:00:00"
  return (
    <Layout title={"Pascendi Gregis Catholic Homeschool Support Program"}>
      <div className="home">
        <PascendiBannerLayout2 />
        <section className="bg-[#c89d69] flex flex-col text-white py-36">
          <div className="grid grid-cols-1 lg:grid-cols-[1fr_auto] w-10/12 max-w-[700px] mx-auto gap-y-20 gap-x-5">
            <h2 className="text-center w-full font-black text-4xl ">
              <nobr>ABOUT US</nobr>
            </h2>
            <p>
              Pascendi Gregis is a private entity, owned and operated by members
              and parishioners of the Society of Saint Pius X (SSPX). Pascendi
              Gregis has worked out an agreement to occupy some space on the
              church grounds and to obtain spiritual guidance from our Priests.
            </p>

            <h2 className="text-center w-full font-black text-4xl ">
              MISSION STATEMENT
            </h2>
            <p>
              Our mission is to assemble a family-oriented community that grows
              together in a Traditional Catholic environment for the purpose of
              creating holy vocations and catholic families. As it is spelled
              out in the statutes of the Society of St. Pius X:
              <br />
              <br />
              <span className="w-10/12 mx-auto italic py-5">
                "Schools, truly free and unfettered, able to bestow on youth a
                thoroughly Christian education, shall be fostered and, if need
                be, founded by the members of the Society. From these will come
                vocations and Christian homes" (Statutes III, 4).
              </span>
            </p>

            <h2 className="text-center w-full font-black text-4xl ">
              VISION STATEMENT
            </h2>
            <p>
              Our vision is to lead our youth into a Traditional Catholic School
              with the proper discipline, education and faith, under the shield
              and guidance of the Priestly Society of Saint Pius X, for the
              future of our children, our community and Holy Mother The Church
            </p>
          </div>
        </section>

        {/* Registration Section */}
        {/* <section className="flex justify-center items-center flex-col py-40 relative bg-yellow-50 gap-10">
          <div className="absolute -top-[11rem] left-[50%] -translate-x-[50%] border-b-[#c89d69] border-r-[#c89d69] border-b-[5rem] border-r-[5rem] w-48 h-48 rotate-45"></div>
          <div className="absolute -bottom-[11rem] left-[50%] -translate-x-[50%] border-b-[#193f65] border-r-[#193f65] border-b-[5rem] border-r-[5rem] w-48 h-48 -rotate-[135deg]"></div>
          <h2 className="text-center w-full font-black text-4xl py-5 text-sky-600">
            DAYS LEFT TO REGISTER
          </h2>

          <div>
            <Countdown targetDate={targetDate} />
          </div>
          <Link
            to="/registration"
            className="bg-sky-700 flex justify-center items-center gap-2 flex-row px-6 p-4 text-xl drop-shadow-md rounded-full border-b-2 border-cyan-500 shadow-lg translate-y-0 hover:shadow-none hover:translate-y-1 hover:bg-sky-800 text-white hover:text-amber-300 duration-300 ease-in-out group"
          >
            <div className="bg-white w-5 h-5 relative rounded-full border-2 border-sky-900 group-hover:border-lime-500">
              <div className="absolute top-0 left-0 w-4 h-4 rounded-full bg-gradient-to-b from-green-600  to-lime-500 p-[.1rem] z-20 ">
                <div className="border-t border-white w-full rounded-full h-full"></div>
              </div>
              <div className="absolute top-0 left-0 w-4 h-4 rounded-full bg-lime-500 p-[.1rem] group-hover:animate-ping z-0"></div>
            </div>
            BEGIN REGISTRATION
          </Link>
        </section> */}

        <section className="courses centerAll">
          <CourseCards />
        </section>

        <section
          id="calendar"
          className="calendar flex-col flex justify-center items-center min-h-[130vh] h-full py-10 bg-white relative z-10"
        >
          <h2 className="w-full text-center text-cyan-700 font-bold text-5xl leading-6">
            CALENDAR <br />
            <span className="text-base font-normal ">
              Check out our calendar for upcoming events
            </span>
          </h2>
          <Calendars className="flex justify-center items-center" />
          {/* <DailySchedule className="w-10/12 mx-auto" /> */}
        </section>

        <section className="py-40 flex justify-center items-center flex-col">
          <h2 className="text-center w-full font-black text-4xl py-5">
            CONTACT US
          </h2>
          <a href="mailto:pascendigregis@gmail.com">pascendigregis@gmail.com</a>
          305.306.3509
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
